<template>
  <div>
    <el-dialog
      title="缺货原因打标"
      :visible.sync="visible"
      width="90%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="ruleFormRef"
        v-loading.lock="fullscreenLoading"
        :model="ruleForm"
        label-width="80px"
      >
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="12"><span v-if="checkedParams.statisticsMethodDict!=='ALL'">【{{ ruleForm.stockChannelName }}】：</span>{{ `${ruleForm.styleName||''}/${ruleForm.colorName||''}` }}</el-col>
          <el-col :span="12">
            <el-form-item label="缺货原因" required :rules="{required: true, message:'必填', trigger: 'blur' }" prop="shortageCauseDict">
              <Select
                v-model="ruleForm.shortageCauseDict"
                :select-options="_getAllCommodityDict('DSB_SHORTAGE_CAUSE')"
                :configuration="{ key: 'val', label: 'label', value: 'val' }"
                clearable
              />
            </el-form-item>

          </el-col>
        </el-row>
        <el-divider />

        <el-descriptions v-loading.lock="fullscreenLoading" class="margin-top" title="" :column="4" label-class-name="my-label">
          <el-descriptions-item label="统计时段">{{ `${ruleForm.statisticsStartDate||''}~${ruleForm.statisticsEndDate||''}` }}</el-descriptions-item>
          <el-descriptions-item label="预测销量">{{ ruleForm.forecastSales }}</el-descriptions-item>
          <el-descriptions-item label="快船缺口">{{ ruleForm.fastGap }}</el-descriptions-item>
          <el-descriptions-item label="普船缺口">{{ ruleForm.genGap }}</el-descriptions-item>
          <el-descriptions-item label="超卖情况说明" :span="2">
            <div v-if="ruleForm.oversoldList.length">
              <div v-for="(item,index) in ruleForm.oversoldList" :key="index">
                {{ `${item.saleDate}/${item.skuName}/${item.totalForecastSales}/${item.totalActualSales}` }}
              </div>
            </div>
            <div v-else>无超卖数据</div>
          </el-descriptions-item>
          <el-descriptions-item label="产品定位">{{ ruleForm.positionName }}</el-descriptions-item>
          <el-descriptions-item label="弃置产品标识">{{ ruleForm.productSymbolName }}</el-descriptions-item>
          <el-descriptions-item label="交付情况说明" :span="4">
            <div v-if="ruleForm.deliveryList.length">
              <div v-for="(item,index) in ruleForm.deliveryList" :key="index">
                {{ `【${item.stockChannelName}】${item.purchaseOrderCode}/${item.number}/${item.expectDeliveryDate}/${item.planDeliveryDate}/${item.purchaseDeliveryDate}` }}
              </div>
            </div>
            <div v-else>无采购到货反馈交期晚于计划评审交期7天以上的单据</div>
          </el-descriptions-item>

          <el-descriptions-item label="物流情况说明" :span="4">
            <div v-if="ruleForm.logisticsList.length">
              <div v-for="(item,index) in ruleForm.logisticsList" :key="index">
                {{ `${item.outboundCode}/${item.toWarehouseName}/${item.number}/${item.expectShelfDate}/${item.estimateShelfDate}/${item.actualShelfStartDate}` }}
              </div>
            </div>
            <div v-else>无晚于期望上架日期的单据</div>
          </el-descriptions-item>

          <el-descriptions-item label="新品首批到货说明" :span="2">
            <div v-if="ruleForm.newFirstArrivalList.length">
              <div v-for="(item,index) in ruleForm.newFirstArrivalList" :key="index">
                {{ `【${item.stockChannelName}】${item.skuName||item.styleName}/第一次上架日期为${item.firstShelfDate&&item.firstShelfDate.split(' ')[0] }` }}
              </div>
            </div>
            <div v-else>暂无上架信息</div>
          </el-descriptions-item>

          <el-descriptions-item label="新品翻单说明" :span="2">
            <div v-if="ruleForm.newReorderList.length">
              <div v-for="(item,index) in ruleForm.newReorderList" :key="index">
                {{ `【${item.stockChannelName}】${item.purchaseOrderCode}/${item.number}/${item.orderDate}` }}
              </div>
            </div>
            <div v-else>无翻单</div>
          </el-descriptions-item>

          <el-descriptions-item label="多渠道配送说明" :span="2">
            <div v-if="ruleForm.moreChannelDistributionList.length">
              <div v-for="(item,index) in ruleForm.moreChannelDistributionList" :key="index">
                {{ `【${item.stockChannelName}】 ${item.platformName}/${item.siteName}/${item.shopName}/${item.number}` }}
              </div>
            </div>
            <div v-else>无跨渠道订单履约数据</div>
          </el-descriptions-item>

          <el-descriptions-item label="平台仓退仓说明" :span="2">
            <div v-if="ruleForm.platWarehouseOutList.length">
              <div v-for="(item,index) in ruleForm.platWarehouseOutList" :key="index">
                {{ `${item.warehouseName}/${item.number}` }}
              </div>
            </div>
            <div v-else>无平台仓退仓数据</div>
          </el-descriptions-item>
        </el-descriptions>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">取 消</el-button>
        <el-button type="primary" :loading="loading" :disabled="fullscreenLoading" @click="handleSubmit">{{ `提交 ${currentIndex}/${tableData.length}` }}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import { shortageCauseSubmit, shortageCauseView, shortageCauseViewByPage } from '@/api/configuration'

export default {
  components: { Select },

  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    checkedParams: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      fullscreenLoading: false,
      tableData: [],
      currentIndex: 1,
      loading: false,
      ruleForm: {
        shortageCauseDict: '',
        statisticsStartDate: '',
        oversoldList: [],
        deliveryList: [],
        logisticsList: [],
        newFirstArrivalList: [],
        newReorderList: [],
        moreChannelDistributionList: [],
        platWarehouseOutList: []
      }
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    simpleViewParams() {
      const pageViewKey = this.tableData[this.currentIndex - 1]
      return Object.assign({}, { pageViewKey }, this.checkedParams,)
    },
    submitParams() {
      const pageViewKey = this.tableData[this.currentIndex - 1]
      const { shortageCauseDict } = this.ruleForm
      const shortageCauseName = this.getAuditTypeLabel('DSB_SHORTAGE_CAUSE', shortageCauseDict)

      const { statisticsMethodDict, statisticsMethodName } = this.checkedParams

      return Object.assign({}, this.ruleForm, { shortageCauseName },
        pageViewKey, { statisticsMethodDict, statisticsMethodName })
    }

  },
  watch: {
    'visible'(val) {
      if (val) {
        this.$refs.ruleFormRef?.clearValidate()
        this.$refs.ruleFormRef?.resetFields()
        // 请求分页数据
        this.getDefaultData()
      } else {
        this.currentIndex = 1
      }
    }

  },
  created() {

  },
  mounted() {

  },
  methods: {
    async getSingleView() {
      try {
        this.fullscreenLoading = true
        const { datas } = await shortageCauseViewByPage(this.simpleViewParams)
        this.ruleForm = datas
      } finally {
        this.fullscreenLoading = false
      }
    },
    async getDefaultData() {
      const { datas } = await shortageCauseView(this.checkedParams)
      this.tableData = datas
      datas && datas.length && this.getSingleView()
    },
    handleSubmit() {
      this.$refs.ruleFormRef.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const { code } = await shortageCauseSubmit(this.submitParams)
            if (code === 0) {
              this.currentIndex++
              // 重新获取预览数据
              if (this.currentIndex <= this.tableData.length) {
                this.ruleForm.shortageCauseDict = ''
                this.getSingleView()
              } else {
                this.$emit('input', false)
                this.$emit('update')
              }
            }
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>
<style>
.my-label {
  font-size:15px;
  font-weight:700;
}
</style>

