<template>
  <div>
    <QueryForm
      v-model="queryForm"
      show-export
      :query-btn-loading="tableLoading"
      @handleExport="handleExport"
      @query="handleQuery"
      @reset="handleQueryFormReset"
    >
      <el-form-item label="起止日期">
        <el-date-picker
          v-model="queryForm.value"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="备货渠道">
        <Select
          v-model="queryForm.stockChannelNameList"
          api-key="StockChannelList"
          clearable
          :configuration="{
            key: 'channelId',
            label: 'channelName',
            value: 'channelName',
          }"
          multiple
        />
      </el-form-item>
      <el-form-item label="产品类目">
        <el-cascader
          v-model="queryForm.productCategoryNameList"
          clearable
          collapse-tags
          filterable
          separator=">"
          :options="productcategorydatas"
          class="style_w100"
          :props="{
            value: 'categoryCnName',
            label: 'categoryCnName',
            children: 'childList',
            multiple: true,
          }"
        />
      </el-form-item>
      <el-form-item label="商品类目">
        <el-cascader
          v-model="queryForm.categoryNameList"
          clearable
          collapse-tags
          filterable
          separator=">"
          :options="basecategorydatas"
          class="style_w100"
          :props="{
            value: 'categoryName',
            label: 'categoryName',
            children: 'sonCategory',
            multiple: true,
          }"
        />
      </el-form-item>

      <el-form-item label="产品定位">
        <Select
          v-model="queryForm.positionNameList"
          style="width: 100%"
          api-key="position"
          :configuration="{
            key: 'stylePositionId',
            label: 'position',
            value: 'position',
          }"
          clearable
          filterable
          collapse-tags
          multiple
        />
      </el-form-item>
      <el-form-item label="品牌">
        <Select
          v-model="queryForm.brandNameList"
          api-key="brandList"
          clearable
          :configuration="{
            key: 'id',
            label: 'brandName',
            value: 'brandName',
          }"
          multiple
        />
      </el-form-item>
      <el-form-item label="BG">
        <Select
          v-model="queryForm.bgNameList"
          api-key="brandList"
          clearable
          multiple
          :configuration="{
            key: 'id',
            label: 'brandName',
            value: 'brandName',
          }"
        />
      </el-form-item>
      <el-form-item label="BU">
        <Select
          v-model="queryForm.buNameList"
          api-key="buList"
          clearable
          multiple
          :configuration="{
            key: 'id',
            label: 'buName',
            value: 'buName',
          }"
        />
      </el-form-item>
      <el-form-item label="适用季节">
        <Select
          v-model="queryForm.seasonNameList"
          :select-options="_getAllCommodityDict('SEASON')"
          :configuration="{ key: 'val', label: 'label', value: 'label' }"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="上市季节">
        <Select
          v-model="queryForm.launchSeasonDictValueList"
          api-key="listLaunchSeason"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="缺货原因">
        <Select
          v-model="queryForm.shortageCauseList"
          :select-options="_getAllCommodityDict('DSB_SHORTAGE_CAUSE')"
          :configuration="{ key: 'val', label: 'label', value: 'label' }"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="Style">
        <Select
          v-model="queryForm.styleId"
          api-key="styleList"
          clearable
          @change="handleChangeStyle"
          @responseData="(val) => (styleList = val)"
        />
      </el-form-item>
      <el-form-item label="Color">
        <Select
          v-model="queryForm.colorNameList"
          api-key="colorList"
          :params="{ styleId: queryForm.styleId }"
          ganged
          :configuration="{ key: 'id', label: 'colorName', value: 'colorName' }"
          not-required-params
          multiple
          collapse-tags
        />
      </el-form-item>
      <el-form-item label="Size">
        <Select
          v-model="queryForm.sizeNameList"
          not-required-params
          api-key="sizeList"
          :params="{ styleId: queryForm.styleId }"
          ganged
          multiple
          collapse-tags
          :configuration="{ key: 'id', label: 'size', value: 'size' }"
        />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input
          v-model="queryForm.skuNameList"
          :placeholder="$t('page.inputPlaceholder')"
        />
      </el-form-item>
      <el-form-item label="统计方式：">
        <el-radio-group v-model="queryForm.statisticsMethodDict">
          <el-radio v-for="(item,index) in _getAllCommodityDict('DSB_STATISTICS_METHOD')" :key="index" :label="item.val">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="库存缺口：">
        <el-checkbox-group v-model="queryForm.stockGapList">
          <el-checkbox :label="0">快船缺货</el-checkbox>
          <el-checkbox :label="1">普船缺货</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="缺货分析：">
        <el-checkbox-group v-model="queryForm.tagFlagList">
          <el-checkbox :label="0">未打标</el-checkbox>
          <el-checkbox :label="1">已打标</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </QueryForm>
    <div class="mb-3">
      <el-button @click="handleStockAna">缺货分析</el-button>
      <el-button type="primary" @click="handleCalAgain">重新计算</el-button>
    </div>
    <Table
      :table-data="tableData"
      max-height="600px"
      check
      show-overflow-tooltip
      :columns="columns.commoditySupplyAndShortageStatistics"
      :loading="tableLoading"
      @handleSelectionChange="handleSelectionChange"
    />
    <Paging :pager="pager" end class="mt-3" @pagination="pagerUpdate" />

    <CauseMarking v-model="dialogVisible" :checked-params="checkedParams" @update="handleQuery" />
  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import Table from '@/components/Table'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Pagination'
import CauseMarking from '../components/CauseMarking'
import {
  recalculateChannel,
  recalculate,
  getBasecategory,
  outOfStockChannelPage,
  outOfStockPage,
  exportOutOfStockChannel,
  exportOutOfStock, shortageCauseView
} from '@/api/configuration'

import columns from '@/constant/tablecolumns'
import { handlePageParams } from '@/utils'
import { productCategoryApi } from '@/api/listSelection'

export default {
  components: { QueryForm, Table, Select, Paging, CauseMarking },
  mixins: [commodityInfoDict],

  data() {
    return {
      checkedParams: {},
      multipleTable: [],
      dialogVisible: false,
      productcategorydatas: [],
      columns,
      tableData: [],
      queryForm: {
        styleId: '',
        value: [],
        categoryNameList: [],
        sizeNameList: [],
        statisticsMethodDict: 'STOCK_CHANNEL',
        tagFlagList: [],
        stockGapList: []
      },
      tableLoading: false,
      basecategorydatas: [],
      styleList: [],
      pager: {
        size: 10,
        current: 1
      }
    }
  },
  computed: {
    qeuryParams() {
      return handlePageParams({
        formData: this.queryForm,
        processedParameter: {
          timeField: {
            value: ['startDate', 'endDate']
          },
          lastCategoryIdList: ['categoryNameList', 'productCategoryNameList'],
          toArrayList: ['skuNameList', 'styleNameList'],
          findNameOrIdList: {
            styleNameList: ['styleId', 'id', 'styleName', this.styleList]
          }
        }
      })
    }
  },
  watch: {},
  mounted() {
    this._getBasecategory()
    this._getProductCategory()
    this.handleQuery()
  },
  methods: {
    async handleStockAna() {
      let selectSkuChannelDateList = []

      const { multipleTable, queryForm } = this
      const { statisticsMethodDict, stockGapList, tagFlagList, value } = queryForm
      const [startDate, endDate] = value || []

      if (!(statisticsMethodDict && startDate && endDate && stockGapList?.length && tagFlagList?.length)) {
        return this.$message.warning('起止时间、统计方式、库存缺口、缺货分析条件必选')
      }

      const statisticsMethodName = this.getAuditTypeLabel('DSB_STATISTICS_METHOD', statisticsMethodDict)

      if (multipleTable.length) {
        selectSkuChannelDateList = this.multipleTable.map((v) => {
          const { demandDate, skuName, stockChannelId } = v
          return { demandDate, skuName, stockChannelId }
        })
      }

      this.checkedParams = Object.assign({}, { statisticsMethodName, startDate, endDate, selectSkuChannelDateList, statisticsMethodDict, stockGapList, tagFlagList })

      const { datas } = await shortageCauseView(this.checkedParams)
      if (!Array.isArray(datas) || !datas.length) return this.$message.warning('暂无可预览数据')

      this.dialogVisible = true
    },

    handleCalAgain() {
      this.$confirm(
        '将重新计算并覆盖当前版本商品缺货数据，请确认',
        this.$t('page.Prompt'),
        {
          confirmButtonText: this.$t('title.confirm'),
          cancelButtonText: this.$t('title.cancel'),
          type: 'warning'
        }
      ).then(async() => {
        this.queryForm.statisticsMethodDict === 'STOCK_CHANNEL'
          ? await recalculateChannel()
          : await recalculate()
        this.$message.success('操作成功')
        this.handleQuery()
      })
    },
    handleSelectionChange(val) {
      this.multipleTable = val
    },
    async _getProductCategory() {
      const { datas } = await productCategoryApi()
      this.productcategorydatas = datas
    },
    async handleExport() {
      this.queryForm.statisticsMethodDict === 'STOCK_CHANNEL'
        ? await exportOutOfStockChannel(this.qeuryParams)
        : await exportOutOfStock(this.qeuryParams)
      this.$notify({
        message: '操作成功，请前往文件导出界面下载',
        type: 'success'
      })
    },
    async _getBasecategory() {
      const { datas } = await getBasecategory()
      this.basecategorydatas = datas
    },

    pagerUpdate(val) {
      this.pager = val
      this.handleClick()
    },
    handleChangeStyle() {
      this.queryForm.colorNameList = []
      this.queryForm.sizeNameList = []
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    handleQuery() {
      this.pager.current = 1
      this.handleClick()
    },
    async handleClick() {
      try {
        this.tableLoading = true
        const { statisticsMethodDict } = this.queryForm
        const {
          datas: { records, current, size, total }
        } =
          statisticsMethodDict === 'STOCK_CHANNEL'
            ? await outOfStockChannelPage(this.pager, this.qeuryParams)
            : await outOfStockPage(this.pager, this.qeuryParams)
        this.tableData = records || []
        this.pager = { current, size, total }
      } finally {
        this.tableLoading = false
      }
    }
  }
}
</script>

<style></style>
